import { useState, useEffect } from "react";
import { ethers } from "ethers";
import erc20abi from "./abi.json";
import TxList from "./txList";
import logo from "./assets/img/logo.png";
import incomeDistribution from "./assets/img/income-distribution.png";
import "./assets/css/style.css";

export default function App() {
  const [txs, setTxs] = useState([]);
  const [contractListened, setContractListened] = useState();
  const [error, setError] = useState();
  const [contractInfo, setContractInfo] = useState({
    address: "-",
    tokenName: "-",
    tokenSymbol: "-",
    totalSupply: "-"
  });
  const [balanceInfo, setBalanceInfo] = useState({
    address: "-",
    balance: "-"
  });

  useEffect(() => {
    if (contractInfo.address !== "-") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const erc20 = new ethers.Contract(
        contractInfo.address,
        erc20abi,
        provider
      );

      erc20.on("Transfer", (from, to, amount, event) => {
        console.log({ from, to, amount, event });

        setTxs((currentTxs) => [
          ...currentTxs,
          {
            txHash: event.transactionHash,
            from,
            to,
            amount: String(amount)
          }
        ]);
      });
      setContractListened(erc20);

      return () => {
        contractListened.removeAllListeners();
      };
    }
  }, [contractInfo.address]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const erc20 = new ethers.Contract(data.get("addr"), erc20abi, provider);

    const tokenName = await erc20.name();
    const tokenSymbol = await erc20.symbol();
    const totalSupply = await erc20.totalSupply();

    setContractInfo({
      address: data.get("addr"),
      tokenName,
      tokenSymbol,
      totalSupply
    });
  };

  const getMyBalance = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const erc20 = new ethers.Contract(contractInfo.address, erc20abi, provider);
    const signer = await provider.getSigner();
    const signerAddress = await signer.getAddress();
    const balance = await erc20.balanceOf(signerAddress);

    setBalanceInfo({
      address: signerAddress,
      balance: String(balance)
    });
  };

  const handleTransfer = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const erc20 = new ethers.Contract(contractInfo.address, erc20abi, signer);
    await erc20.transfer(data.get("recipient"), data.get("amount"));
  };

  return (
    <div className="p-4 credit-card w-full lg:w-3/4 sm:w-auto mx-auto rounded-xl">
      <h1 className="mt-4 p-4 text-center">
        <img
          className="mx-auto"
          src={logo}
          width="75%"
          alt="Kronos - Meu Dinheiro Digital, a moeda virtual das instituições dos agentes de créditos" />
          <span class="text-gray-500">Kronos Pay</span>  
      </h1>
      <div className="mt-4 p-4 credit-card w-full lg:w-3/4 sm:w-auto shadow-lg mx-auto rounded-xl bg-white">
        <form className="m-4" onSubmit={handleSubmit}>
          <div>
            <main className="mt-4 p-4">
              <h2 className="mb-4 text-xl font-semibold text-gray-700 uppercase text-center">
                Área gerencial de contratos para instituições 
              </h2>
              <p className="pt-0">
                Análise da proposta de moeda social digital "Meu Dinheiro Virtual" (MDV) para transações financeiras descentralizadas na Blockchain através do Token ERC-20. Por favor, informe:
              </p>
              <div>
                <div className="my-3">
                  <input
                    type="text"
                    name="addr"
                    className="input input-bordered block w-full focus:ring focus:outline-none"
                    placeholder="Endereço do contrato"
                  />
                </div>
              </div>
              <p><b>Observação:</b> Crie o seu Token em: <a href="https://remix.ethereum.org/">Remix Ethereum</a>. Caso queira apenas testar nossas funcionalidades, você pode usar o endereço da moeda social digital "Meu Dinheiro Virtual" (MDV) abaixo como modelo:</p>
              <p className="mt-4 p-2 bg-gray-100 rounded-sm">
                <code>0x2CCC0b4f871B495b43A65bf4f932767d635a4386</code>
              </p>
            </main>
            <footer className="pt-0 p-4">
              <button
                type="submit"
                className="btn btn-primary submit-button focus:ring focus:outline-none w-full"
              >
                Buscar informações
              </button>
            </footer>
            <h2 className="mb-4 text-xl font-semibold text-gray-700 uppercase text-center">
              Painel de análises estratégicas
            </h2>
            <p className="mt-4 p-4">
              Acompanhe as informações essencias do Token lastreável através da Blockchain Goreli, rede de testes da Ethereum.
            </p>
            <div className="px-4">
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Símbolo</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{contractInfo.tokenName}</td>
                      <td>{contractInfo.tokenSymbol}</td>
                      <td>{String(contractInfo.totalSupply)}</td>
                      <td>{contractInfo.deployedAt}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="p-4">
              <button
                onClick={getMyBalance}
                type="submit"
                className="btn btn-primary submit-button focus:ring focus:outline-none w-full"
              >
                Visualizar saldo
              </button>
            </div>
            <div className="px-4">
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Carteira</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{balanceInfo.address}</td>
                      <td>{balanceInfo.balance}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="mt-4 p-4 credit-card w-full lg:w-3/4 sm:w-auto shadow-lg mx-auto rounded-xl bg-white">
          <div className="mt-4 p-4">
            <h2 className="mb-4 text-xl font-semibold text-gray-700 uppercase text-center">
              Distribuição de renda 
              <img
                className="mx-auto mt-2 p-4"
                src={incomeDistribution}
                width="50%"
                alt="Kronos - Meu Dinheiro Digital, a moeda virtual das instituições dos agentes de créditos" />
            </h2>
            <p className="pt-0">
              Popularize o uso do seu Teoken transferindo fundos para carteiras específicas.
            </p>
            <form onSubmit={handleTransfer}>
              <div className="my-3">
                <input
                  type="text"
                  name="recipient"
                  className="input input-bordered block w-full focus:ring focus:outline-none"
                  placeholder="Endereço do destinatário"
                />
              </div>
              <div className="my-3">
                <input
                  type="number"
                  step="1"
                  name="amount"
                  className="input input-bordered block w-full focus:ring focus:outline-none"
                  placeholder="Valor"
                />
              </div>
              <footer>
                <button
                  type="submit"
                  className="btn btn-primary submit-button focus:ring focus:outline-none w-full"
                >
                  Transferir
                </button>
              </footer>
            </form>
          </div>
        </div>
      <div className="mt-4 p-4 credit-card w-full lg:w-3/4 sm:w-auto shadow-lg mx-auto rounded-xl bg-white">
        <div className="m-4">
          <div className="mt-4 p-4">
            <h2 className="text-xl font-semibold text-gray-700 uppercase text-center">
              Transações recentes
            </h2>
            <TxList txs={txs} />
          </div>
        </div>
      </div>
    </div>
  );
}
