export default function TxList({ txs }) {
  if (txs.length === 0) return null;
  return (
    <>
      {txs.map((item) => (
        <div key={item.txHash} className="alert-info mt-5 rounded-xl py-2 px-4">
          <div>
            <p><b>De:</b> {item.from}</p>
            <p><b>Para:</b> {item.to}</p>
            <p><b>Valor:</b> {item.amount}</p>
            <a href={`https://goerli.etherscan.io/tx/${item.txHash}`} target="_blank">
                Visualizar na Blockchain
            </a>
          </div>
        </div>
      ))}
    </>
  );
}
